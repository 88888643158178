import { FC } from 'react';

import { useLinkComponent } from '@link';
import { TopMenuRendering } from '@sitecore/types/manual/NavigationBar';
import { NavLink, MobileHeader, DesktopHeader } from '@sparky';

import usePlaceholderComponentContent from './usePlaceholderComponentContent';

const Items: FC<{ items: TopMenuRendering['fields']['items'] }> = ({ items }) => {
  const Link = useLinkComponent();

  return (
    <>
      {items.map(item => (
        <li key={item.id}>
          <Link href={item.fields.link.value.href} linkType={item.fields.link.value.linktype}>
            <NavLink variant="tertiary">{item.fields.link.value.text}</NavLink>
          </Link>
        </li>
      ))}
    </>
  );
};

interface Props {
  platform: 'mobile' | 'desktop';
  languageSelector?: React.ReactNode;
}

const TopMenu: FC<Props> = ({ platform, languageSelector }) => {
  const content = usePlaceholderComponentContent<TopMenuRendering>('jss-topheader', 'TopMenu');
  if (!content?.fields.items) return null;
  if (platform === 'desktop') {
    return (
      <DesktopHeader.Top languageSelector={languageSelector}>
        <Items items={content.fields.items} />
      </DesktopHeader.Top>
    );
  }
  return (
    <MobileHeader.Top>
      <Items items={content.fields.items} />
    </MobileHeader.Top>
  );
};

export default TopMenu;
