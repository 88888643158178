import { signOut } from 'next-auth/react';

import env from '@common/env';
import { useSession } from '@dxp-auth';
import { NavLink } from '@sparky';
import { LogoutIcon } from '@sparky/icons';

export const LogoutButton: React.FC<Props> = ({ label = 'Uitloggen' }) => {
  const { data: session } = useSession() ?? {};

  if (session) {
    const oktaSignoutUrl = `${env('OKTA_OAUTH2_LOGOUT')}?id_token_hint=${
      session.idToken
    }&post_logout_redirect_uri=${env('OKTA_OAUTH2_LOGOUT_REDIRECT_URI')}`;

    return (
      <NavLink
        variant="secondary"
        onClick={() => {
          signOut({ redirect: false }).then(() => (window.location.href = oktaSignoutUrl));
        }}
        leftIcon={<LogoutIcon />}>
        {label}
      </NavLink>
    );
  }

  return null;
};

interface Props {
  label?: string;
}
