import logger from '@common/log';
import { Illustrations } from '@sitecore/types/manual/Illustrations';
import * as SparkyIllustrations from '@sparky/illustrations';
import { Placeholder } from '@sparky/illustrations';

export const getIllustration = (illustrationName: Illustrations) => {
  if (illustrationName in SparkyIllustrations) {
    return SparkyIllustrations?.[illustrationName];
  }
  logger.error('wVRxzv', `Illustration ${illustrationName} could not be found. A placeholder will be rendered.`);
  return Placeholder;
};
