import React, { FC, useEffect, useRef } from 'react';

import { isForwardRef } from 'react-is';

type DataSetProps = {
  cy?: string;
  label?: string;
};

type Ref = React.MutableRefObject<HTMLDivElement | null>;

const withRef = (child: React.ReactNode, ref: Ref) => {
  if (isForwardRef(child)) {
    return React.cloneElement(child, { ref });
  }

  return <div ref={ref}>{child}</div>;
};

/*
 * Component to set `data-*` attributes to its first child. For `forwardRef`
 * components we can use the `ref` and prevent the extra `<div>`.
 *
 * The provided props/keys must exclude `data-`.
 *
 * Example: `<DataSet label="Some description">` - to be picked up by the generic <TrackingProvider> for GA.
 *
 * Bad example: `<DataSet cy="component">` - to set `data-cy` attribute.
 * Try to use better a11y-based selectors in Jest and Cypress tests.
 */
export const DataSet: FC<React.PropsWithChildren<DataSetProps>> = ({ children, ...store }) => {
  const ref: Ref = useRef(null);

  useEffect(() => {
    if (ref?.current && store) {
      for (const [key, value] of Object.entries(store)) {
        ref.current.dataset[key] = value;
      }
    }
  }, [store]);

  const [first, ...rest] = React.Children.toArray(children);

  return (
    <>
      {withRef(first, ref)}
      {rest}
    </>
  );
};
