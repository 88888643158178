import { FC } from 'react';

import { useCustomerGetCustomerProfile } from '@dc/hooks';
import { Box, Stack, Text } from '@sparky';
import { ProfileIcon } from '@sparky/icons';
import { SensitiveContentComponent } from '@tracking';

interface Props {
  label: string;
}

export const ProfileArea: FC<Props> = ({ label }) => {
  const { data: profile } = useCustomerGetCustomerProfile();

  if (!profile) return null;

  return (
    <Box paddingY="2" paddingX="5">
      <Stack direction="row" alignY="center">
        <Box paddingRight="2">
          <ProfileIcon />
        </Box>
        <Stack direction="column">
          <SensitiveContentComponent contentMask="masked contact name">
            <Text weight="bold" size="BodyM">
              {profile.contact?.name}
            </Text>
          </SensitiveContentComponent>
          <Text as="p" size="BodyS">
            {profile.customerId}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
};
