import { signIn } from 'next-auth/react';

import { useSession } from '@dxp-auth';
import { useRouter } from '@dxp-next';
import { NavLink } from '@sparky';

export const LoginButton: React.FC<Props> = ({ leftIcon, label: signInLabel = 'Inloggen', callbackUrl }) => {
  const { data: session, status } = useSession() ?? {};
  const { basePath } = useRouter();

  if (session || status === 'loading') {
    return null;
  }

  return (
    <NavLink
      leftIcon={leftIcon}
      onClick={() =>
        signIn('okta', {
          callbackUrl: `${basePath}${callbackUrl}`,
        })
      }>
      {signInLabel}
    </NavLink>
  );
};

interface Props {
  leftIcon?: JSX.Element;
  label?: string;
  callbackUrl?: string;
}
