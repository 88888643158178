import React, { FC, useState, useEffect, useRef } from 'react';

import { LogoutButton } from '@components-next/auth';
import { useLinkComponent } from '@link';
import { getIllustration } from '@sitecore/common';
import { ProfileMenuRendering, Item as ProfileMenuRenderingItem } from '@sitecore/types/manual/NavigationBar';
import { NavLink, Box, Stack, Divider, Text, TransitionSlide, Stretch } from '@sparky';
import { ChevronLeftIcon, ChevronRightIcon } from '@sparky/icons';

import { ProfileArea } from './LoginMenu/ProfileArea';
import usePlaceholderComponentContent from './usePlaceholderComponentContent';

interface Props {
  setIsCloseButtonVisible?: (isVisible: boolean) => void;
}

const ProfileMenu: FC<Props> = ({ setIsCloseButtonVisible }) => {
  const [activeSecondaryMenu, setActiveSecondaryMenu] = useState(null as ProfileMenuRenderingItem | null);
  const [isSecondaryMenuShown, setIsSecondaryMenuShown] = useState(false);
  const profileMenuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (activeSecondaryMenu && profileMenuRef.current) {
      profileMenuRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeSecondaryMenu]);

  const closeSecondaryMenu = () => {
    setActiveSecondaryMenu(null);
    setIsSecondaryMenuShown(false);
  };

  const Link = useLinkComponent();
  const content = usePlaceholderComponentContent<ProfileMenuRendering>('jss-side-navigation', 'ProfileMenu');
  useEffect(() => {
    setIsCloseButtonVisible && setIsCloseButtonVisible(activeSecondaryMenu ? false : true);
  }, [activeSecondaryMenu, setIsCloseButtonVisible]);

  if (!content) return null;

  const { fields } = content;
  const menuItems = fields?.items?.filter(item => item.fields?.link?.value?.text);

  const handleSubMenuClick = (item: ProfileMenuRenderingItem) => {
    setIsSecondaryMenuShown(true);
    setActiveSecondaryMenu(item);
  };

  const MainMenu: FC = () => (
    <>
      <div ref={profileMenuRef}>
        <ProfileArea label={fields.customerNumberLabel.value} />
        <Divider />
      </div>
      {menuItems.length && (
        <Box padding="6">
          <Stack gap="6" as="ul">
            {menuItems.map((item: ProfileMenuRenderingItem) => {
              const { text, href, linktype } = item.fields.link.value;
              const isSubMenu = item.fields?.items?.length;
              const itemContent = isSubMenu ? (
                <li>
                  <Stretch width={true}>
                    <NavLink
                      onClick={() => handleSubMenuClick(item)}
                      rightIcon={<ChevronRightIcon />}
                      justifyContent="spaceBetween">
                      {text}
                    </NavLink>
                  </Stretch>
                </li>
              ) : (
                <li>
                  <Stretch width={true}>
                    <Link href={href} linkType={linktype}>
                      <NavLink>{text}</NavLink>
                    </Link>
                  </Stretch>
                </li>
              );
              return (
                <React.Fragment key={item.id}>
                  {itemContent} <Divider />
                </React.Fragment>
              );
            })}
          </Stack>
        </Box>
      )}
      <Box padding="6">
        <Stack gap="6" as="ul">
          <li>
            <LogoutButton label={fields.logoutLink.value.text || 'Log out'} />
          </li>
        </Stack>
      </Box>
    </>
  );

  const SecondaryMenu: FC = () => (
    <TransitionSlide isEntering={isSecondaryMenuShown} isAbsolute={true} onLeaveFinished={closeSecondaryMenu}>
      <Box paddingX="5" paddingY="4" backgroundColor="backgroundPrimary">
        <Box paddingY="6">
          <Stretch width={true}>
            <NavLink
              isCurrent={true}
              onClick={() => setIsSecondaryMenuShown(false)}
              leftIcon={<ChevronLeftIcon color="iconBrand" />}>
              <Box paddingLeft="7">
                <Text weight="bold" color="textBrand">
                  {activeSecondaryMenu?.fields?.link.value.text}
                </Text>
              </Box>
            </NavLink>
          </Stretch>
        </Box>
        <Divider />
        <Box paddingY="6">
          <Stack gap="6" as="ul">
            {activeSecondaryMenu?.fields.items
              ?.filter(item => item.fields?.link?.value?.text)
              .map((item, key) => {
                const { text, title, href, linktype } = item.fields.link.value;
                const { illustration } = item.fields;
                const Illustration = getIllustration(illustration?.value);
                return (
                  <li key={key}>
                    <Stretch width={true}>
                      <Link href={href} linkType={linktype}>
                        <NavLink>
                          <Stack direction="row" alignY="center">
                            {Illustration && (
                              <Box paddingRight="5">
                                <Illustration size="small" />
                              </Box>
                            )}
                            <Stack>
                              <Text weight="bold">{text}</Text>
                              <Text weight="regular" size="BodyS" color="textLowEmphasis">
                                {title}
                              </Text>
                            </Stack>
                          </Stack>
                        </NavLink>
                      </Link>
                    </Stretch>
                  </li>
                );
              })}
          </Stack>
        </Box>
      </Box>
    </TransitionSlide>
  );

  return (
    <>
      <MainMenu />
      {activeSecondaryMenu && <SecondaryMenu />}
    </>
  );
};

export default ProfileMenu;
