import { FC } from 'react';

import { DataSet } from '@components/util/DataSet/DataSet';
import { useSession } from '@dxp-auth';
import { NavLink, VisuallyHidden } from '@sparky';
import { ChevronDownIcon, ProfileIcon, SpinnerIcon } from '@sparky/icons';
import { useI18nTranslation } from '@sparky/providers';
import { SensitiveContentComponent } from '@tracking';

import LoginMenu from './LoginMenu';

interface Props {
  onOpen: () => void;
  displayName: string | number | undefined;
  isLoading: boolean;
}

export const ProfileMenuLink: FC<Props> = ({ onOpen, displayName, isLoading }) => {

  const { data: session, status } = useSession() ?? {};

  const openProfileMenu = useI18nTranslation('openProfileMenu');

  if (isLoading || status === 'loading') return <SpinnerIcon />;

  if (!session) return <LoginMenu />;


  return (
    <DataSet label={openProfileMenu}>
      <NavLink onClick={onOpen} leftIcon={<ProfileIcon />} rightIcon={<ChevronDownIcon />}>
        <VisuallyHidden>{openProfileMenu}</VisuallyHidden>
        <SensitiveContentComponent contentMask="masked display name">{displayName}</SensitiveContentComponent>
      </NavLink>
    </DataSet>
  );
};
