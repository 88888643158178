import { FC } from 'react';

import { DataSet } from '@components/util/DataSet/DataSet';
import { useLinkComponent } from '@link';
import { SubMenuRendering } from '@sitecore/types/manual/NavigationBar';
import { NavLink, DesktopHeader } from '@sparky';

import usePlaceholderComponentContent from './usePlaceholderComponentContent';

const SecondaryMenu: FC = function () {
  const Link = useLinkComponent();
  const content = usePlaceholderComponentContent<SubMenuRendering>('jss-secondary-navigation', 'SubMenu');
  if (!content?.fields.items) return null;

  const { fields } = content;

  return (
    <DesktopHeader.Sub title={<DataSet cy="subHeaderTitle">{fields.title.value}</DataSet>}>
      {fields.items.map(item => {
        return (
          <li key={item.id}>
            <Link href={item.fields.link.value.href} linkType={item.fields.link.value.linktype}>
              <NavLink variant="secondary" tone="onColor" isCurrent={item.active}>
                {item.fields.link.value.text}
              </NavLink>
            </Link>
          </li>
        );
      })}
    </DesktopHeader.Sub>
  );
};

export default SecondaryMenu;
