// eslint-disable-next-line dxp-rules/no-use-session-from-next-auth
import { SessionContextValue, UseSessionOptions, useSession as useNextAuthSession } from 'next-auth/react';
import { signOut } from 'next-auth/react';

import logger from '@common/log';

import { isNextAuthEnabled } from './isNextAuthEnabled';

const fallbackSession: SessionContextValue = {
  data: null,
  status: 'unauthenticated',
  update: data => Promise.resolve(null),
};

/** Wrapper around the original next-auth useSession that
 * provides a fallback session in case of an error. This way,
 * we can use useSession in containers that do not use Auth.js.
 * {@link https://next-auth.js.org/getting-started/client#usesession View original documentation}
 * @returns {SessionContextValue}
 */
export const useSession = (options?: UseSessionOptions<boolean>) => {
  try {
    const session = useNextAuthSession(options);
    if (session?.data?.error === 'RefreshAccessTokenError') {
      signOut();
    }
    return session;
  } catch (error) {
    if (isNextAuthEnabled) {
      logger.error('5y6Z1G', 'Failed to fetch session', { Error: error });
    }
    return fallbackSession;
  }
};
